class Analytics
{
    constructor()
    {
        this.dataLayer = window.dataLayer;

        this.temps =
        {
            "view":
            {
                "event" : "pageView",
            },
            "impressions":
            {
                "event" : "productFilters",
                "ecommerce" : {"impressions" : [],},
            },
            "click":
            {
                "event" : "productClick",
                "ecommerce" :
                {
                    "click":
                    {
                        "actionField": {"list": "catalog"},
                        "products": [],
                    }
                },
            },
            "detail":
            {
                "event" : "productDetails",
                "ecommerce" :
                {
                    "detail":
                    {
                        "actionField": {"list": "catalog"},
                        "products": [],
                    },
                },
            },
            "add":
            {
                "event" : "addToCart",
                "ecommerce" :
                {
                    "currencyCode": "UAH",
                    "add": {"products": [],}
                },
            },
            "remove":
            {
                "event" : "removeFromCart",
                "ecommerce" :
                {
                    "currencyCode": "UAH",
                    "remove": {"products": []}
                },
            },
            "checkout":
            {
                "event" : "checkout",
                "ecommerce" :
                {
                    "checkout":
                    {
                        "actionField": {"step": 0, "action": "checkout"},
                        "products": [],
                    },
                }
            },
            "option":
            {
                "event": "checkoutOption",
                "ecommerce":
                {
                    "checkout":
                    {
                        "actionField":
                        {
                            "step": 5,
                            //"action": "checkout_option",
                            "option": ""
                        },
                        "products": [],
                    }
                },
            },
            "transaction":
            {
                "event" : "transaction",
                "ecommerce" :
                {
                    "purchase" :
                    {
                        "actionField":
                        {
                            "action": "purchase",
                            "affiliation": "bu.ctrs.com.ua",
                            //"id": 5166766, // ID транзакции
                            "ordertype": "checkoutorder",
                            "revenue": 0,
                            "shipping": "0",
                            "tax": "0"
                        },
                        "products": [],
                    }
                }
            },
        }
    }

    make_impressions(data, event, options)
    {
        let event_data = this.temps[event];
            event_data.ecommerce.impressions = this.prepare_products(data, options);
        return event_data;
    }

    make_click(data, event, options)
    {
        let event_data = this.temps[event];
            event_data.ecommerce.click.products = this.prepare_products(data, options);
        return event_data;
    }

    make_detail(data, event, options)
    {
        let event_data = this.temps[event];
            event_data.ecommerce.detail.products = this.prepare_products(data, options);
        return event_data;
    }

    make_add(data, event, options)
    {
        let event_data = this.temps[event];
            event_data.ecommerce.add.products = this.prepare_products(data, options);
        return event_data;
    }

    make_remove(data, event, options)
    {
        let event_data = this.temps[event];
            event_data.ecommerce.remove.products = this.prepare_products(data, options);
        return event_data;
    }

    make_checkout(data, event, options)
    {
        let event_data = this.temps[event];
            event_data.ecommerce.checkout.products = this.prepare_products(data, options);
            event_data.ecommerce.checkout.actionField.step = options['step'];

        return event_data;
    }

    make_option(data, event, options)
    {
        let event_data = this.temps[event];
            event_data.ecommerce.checkout.products = this.prepare_products(data, options);
            event_data.ecommerce.checkout.actionField.step = options['step'];
            event_data.ecommerce.checkout.actionField.option = options['option'];

        return event_data;
    }

    make_transaction(data, event, options)
    {
        let event_data = this.temps[event];
            event_data.ecommerce.purchase.actionField.revenue = options.revenue;
            event_data.ecommerce.purchase.actionField.ordertype = options.ordertype;
            event_data.ecommerce.purchase.actionField.id = options.order_id;
            event_data.ecommerce.purchase.products = this.prepare_products(data, options);

        return event_data;
    }

    prepare_products(data, options)
    {
        let products = [];

        for(let item in data)
        {
            let product =
            {
                'id'       : data[item]['external_id'],
                'price'    : data[item]['price'],
                'category' : data[item]['category_name'],
                'variant'  : data[item]['grade'],
                'brand'    : data[item]['vendor'],
                'name'     : data[item]['product_name'],
                'quantity' : 1,
                'list'     : 'catalog',
                //'position' : item,
            }

            let valid_options = ['list', 'position'];

            for(let item in options)
            {
                if(valid_options.indexOf(item) > 0)
                product[item] = options[item];
            }

            products.push(product)
        }

        return products;
    }

    push(data, event, options)
    {
        //console.log(data);
        //console.log(event);
        console.log(options);

        let event_data = this['make_' + event](data, event, options)
        this.dataLayer.push(event_data);

        console.log('-------analytics-------');
        console.log(event_data);

    }
}


const analytics = new Analytics();
